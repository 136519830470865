import React from "react";
import { render } from "react-dom";

const init = async() => {
  const { default: Main } = await import("Main");

  render(
    <Main />,
    document.getElementById("root"),
  );
};

init();
